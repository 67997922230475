import { useMemo } from 'react'
import { accounts } from '@unified-ui/ally-next-host'
import { getLastFour } from '@ally/utilitarian'
import { Domains } from '@ally/data-types-host'
import { formatTimeStamp } from '../../../utils'
import { ExtensionNotification, Notification, PendingExtension } from './types'
import { useHostServices } from '../../../HostServices'

function isExtensionNotification(
  notification: Notification,
): notification is ExtensionNotification {
  return notification.type === 'notifications-accounts'
}

export const useAutoPendingExtensions = (): PendingExtension[] => {
  const { hostData } = useHostServices()
  const { notifications } = hostData
  const accountsData = hostData[Domains.AFG_ACCOUNTS].data
  const autoAccounts = accounts.selectors.auto.getAll(accountsData)

  const extensionNotifications: ExtensionNotification[] = useMemo(() => {
    return (
      notifications.data?.notifications.reduce((acc, notification) => {
        if (!isExtensionNotification(notification)) return acc
        if (notification.metadata.hasPendingExtension !== 'true') return acc
        return [...acc, notification]
      }, [] as ExtensionNotification[]) ?? []
    )
  }, [notifications.data])

  const pendingExtensions: PendingExtension[] = useMemo(() => {
    return extensionNotifications.reduce((acc, notification) => {
      const foundAccount = autoAccounts.find(
        account =>
          account.accountNumber === notification.metadata.accountNumber,
      )
      if (!foundAccount) return acc
      const extension = {
        accountId: foundAccount.id,
        lastFour: getLastFour(foundAccount.accountNumber),
        nickname: foundAccount.nickName,
        formattedDate: formatTimeStamp(
          notification.effectiveTill,
          'MMM DD, YYYY',
        ),
      }
      return [...acc, extension]
    }, [] as PendingExtension[])
  }, [extensionNotifications, autoAccounts])

  return pendingExtensions
}
