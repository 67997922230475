import { accounts } from '@unified-ui/ally-next-host'
import { RemoteConfig } from '@ally/federated-types'
import { getLastFour } from '@ally/utilitarian'
import { convertDatesToUTC, dateDiffInDays, formatTimeStamp } from '../../utils'
import { PendingExtension } from './use-auto-pending-extensions'

export interface NotificationTypes {
  [key: string]: boolean | undefined
}

interface Attributes {
  name: string
  value: string
}

export interface AcknowledgedAccount {
  accountId: string
  attributes: Attributes[]
}

export interface LinkConfig {
  content: string
  path: string
}

export interface MboxContent {
  accessibilityTitle?: string
  ctaHref: string
  ctaLabel: string
  ctaNewTab: boolean
  description: string
  iconColor?: string
  iconName: string
  _id?: string
  offerType: string
  title?: string
}

export type NotificationsConfig =
  | { type: 'ETIN'; prefix: string; affix: string }
  | {
      type: 'CD'
      prefix: string
      cdId: string[]
      accountData: string
      isAcknowledged: boolean
    }
  | {
      type: 'CD_MULTIPLE'
      prefix: string
      cdId: string[]
      unAcknowledgedCds: string[]
    }
  | {
      type: 'BANK_MESSAGE'
      prefix: string
      link: LinkConfig
    }
  | { type: 'ADOBE'; contentType: 'html'; html: string }
  | { type: 'ADOBE'; contentType: 'json'; json: MboxContent }
  | ({ type: 'AUTO_PENDING_EXTENSION' } & PendingExtension)
  | { type: 'NO_NOTIFICATIONS'; prefix: string }
  | RemoteConfig

export type MboxType =
  | { action: 'setContent'; content: string }
  | { action: 'setJson'; content: MboxContent[] }

export interface AccountWithAcknowledgement extends accounts.types.Account {
  isAcknowledged: boolean
}

const notifications: NotificationsConfig[] = [
  {
    type: 'ETIN',
    prefix: 'Please ',
    affix: 'in your profile for our records',
  },
]

const noNotifications: NotificationsConfig[] = [
  {
    type: 'NO_NOTIFICATIONS',
    prefix: 'You have no new notifications.',
  },
]

const bankNotification: NotificationsConfig = {
  type: 'BANK_MESSAGE',
  prefix: 'You have a new message.',
  link: {
    content: 'View message',
    path: '/bank/secure-messages',
  },
}

const getAdobeNotifications = (
  localNotifications: Array<MboxType>,
): NotificationsConfig[] | undefined => {
  if (localNotifications?.length) {
    const validatedNotifications: NotificationsConfig[] = []
    localNotifications.forEach(notification => {
      if (!notification.content) return
      if (notification.action === 'setContent') {
        const validatedContent: NotificationsConfig = {
          type: 'ADOBE',
          contentType: 'html',
          html: notification.content,
        }
        validatedNotifications.push(validatedContent)
        return
      }
      if (notification.action === 'setJson') {
        if (!notification.content[0]) return
        const validatedContent: NotificationsConfig = {
          type: 'ADOBE',
          contentType: 'json',
          json: notification.content[0],
        }
        validatedNotifications.push(validatedContent)
      }
    })
    return validatedNotifications
  }
  return undefined
}

const getCdNotification = (
  manageableCds: AccountWithAcknowledgement[],
): NotificationsConfig | undefined => {
  if (manageableCds.length > 1) {
    return {
      type: 'CD_MULTIPLE',
      prefix: `Renewal options are available for ${manageableCds.length} of your CDs. `,
      cdId: manageableCds.map(account => account.id),
      unAcknowledgedCds: manageableCds
        .filter(({ isAcknowledged }) => !isAcknowledged)
        .map(account => account.id),
    }
  }
  if (manageableCds[0]?.indicator?.cdInGracePeriod) {
    return {
      type: 'CD',
      accountData: `${manageableCds[0].nickName} ${getLastFour(
        manageableCds[0].accountNumber,
        '...',
      )}`,
      prefix: ' has matured. ',
      cdId: [manageableCds[0].id],
      isAcknowledged: manageableCds[0].isAcknowledged,
    }
  }
  if (manageableCds[0]?.meta?.cdMaturityDate) {
    return {
      type: 'CD',
      accountData: `${manageableCds[0].nickName} ${getLastFour(
        manageableCds[0].accountNumber,
        '...',
      )}`,
      prefix: ` will mature on ${formatTimeStamp(
        manageableCds[0].meta.cdMaturityDate,
        'MMMM D, YYYY',
      )}. `,
      cdId: [manageableCds[0].id],
      isAcknowledged: manageableCds[0].isAcknowledged,
    }
  }
  return undefined
}

const getNotifications = (
  types: NotificationTypes,
  adobeNotifications: Array<MboxType>,
  manageableCds: AccountWithAcknowledgement[],
  hasBankMessages: boolean,
  remoteNotifications: RemoteConfig[],
  autoPendingExtensions: PendingExtension[],
): NotificationsConfig[] => {
  const toRender = Object.keys(types).reduce(
    (acc, notification): NotificationTypes => {
      if (types[notification]) {
        return {
          ...acc,
          [notification]: types[notification],
        }
      }
      return acc
    },
    {} as NotificationTypes,
  )

  let activeNotifications = notifications.filter(
    notification => Object.keys(toRender).indexOf(notification.type) >= 0,
  )

  // Auto Pending Extensions
  activeNotifications = [
    ...activeNotifications,
    ...autoPendingExtensions.map(data => ({
      type: 'AUTO_PENDING_EXTENSION' as const,
      ...data,
    })),
  ]

  const cdNotification = getCdNotification(manageableCds)
  if (cdNotification) {
    activeNotifications = [...activeNotifications, cdNotification]
  }

  const localAdobeNotifications = getAdobeNotifications(adobeNotifications)
  if (localAdobeNotifications && localAdobeNotifications.length) {
    activeNotifications = [...localAdobeNotifications, ...activeNotifications]
  }

  if (hasBankMessages) {
    activeNotifications = [...activeNotifications, bankNotification]
  }

  if (remoteNotifications.length) {
    activeNotifications = [...remoteNotifications, ...activeNotifications]
  }

  return activeNotifications.length ? activeNotifications : noNotifications
}

const CD_IBUBBLE_REMINDER_DAYS = 30

const mapManageableCds = (
  manageableCds: accounts.types.Account[],
  acknowledgedCds: AcknowledgedAccount[],
): AccountWithAcknowledgement[] => {
  return manageableCds
    .filter(account => {
      const { cdMaturityDate = '' } = account.meta ?? {}
      const { cdInGracePeriod = false } = account.indicator ?? {}
      const now = new Date()
      const maturityDate = new Date(cdMaturityDate)
      const datesUTC = convertDatesToUTC(now, maturityDate)
      const serverDateUTC = datesUTC[0]
      const maturityDateUTC = datesUTC[1]
      const days = dateDiffInDays(maturityDateUTC, serverDateUTC)

      return days < CD_IBUBBLE_REMINDER_DAYS || cdInGracePeriod
    })
    .map(account => {
      return {
        ...account,
        isAcknowledged: acknowledgedCds.some(cd => {
          return (
            account.id === cd.accountId && cd.attributes[0].value === 'true'
          )
        }),
      }
    })
}

export { getNotifications, mapManageableCds }
export type { RemoteConfig }
