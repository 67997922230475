import { useMemo } from 'react'

import { accounts } from '@unified-ui/ally-next-host'

import { Domains } from '@ally/data-types-host'
import { useHostServices } from '../../HostServices'
import { getBankruptcyAcceptedSessionProp } from '../../utils'

export const useShowAccounts = (): boolean => {
  const { hostData, featureFlags } = useHostServices()
  const accountsData = hostData[Domains.AFG_ACCOUNTS].data
  const customizationData = hostData[Domains.CUSTOMIZATION].data

  const isAutoEnabled = featureFlags.variation('FF_SPOG_WEB_UI', true)

  const investAccountsForDropdown = accounts.selectors.invest.getAllForDropdown(
    accountsData,
    customizationData,
  )

  const depositAccountsForDropdown = accounts.selectors.deposit.getAllForDropdown(
    accountsData,
    customizationData,
  )

  const mortgageAccountsForDropdown = accounts.selectors.mortgage.getAllForDropdown(
    accountsData,
    customizationData,
  )

  const creditCardAccountsForDropdown = accounts.selectors.creditCard.getAllForDropdown(
    accountsData,
    customizationData,
  )

  const isBankruptcyAccepted = getBankruptcyAcceptedSessionProp()

  const autoAccountsForDropdown = useMemo(() => {
    return isAutoEnabled
      ? accounts.selectors.auto.getAllForDropdown(
          accountsData,
          isBankruptcyAccepted,
        )
      : []
  }, [hostData, isBankruptcyAccepted, isAutoEnabled])

  const showAccounts =
    [
      investAccountsForDropdown,
      depositAccountsForDropdown,
      mortgageAccountsForDropdown,
      creditCardAccountsForDropdown,
      autoAccountsForDropdown,
    ].flat().length > 0

  return showAccounts
}
