import React, { useRef, useState } from 'react'
import SlidingDrawer from '@ally/metronome-sliding-drawer'
import styled from 'styled-components'
import { useMediaSize } from '@ally/metronome-ui'
import { useHostServices } from '../../HostServices'
import { NavDrawerTrigger } from './NavDrawerTrigger'
import { NavDrawerContent } from './NavDrawerContent'
import { getLogout } from '../../utils'

const StyledSlidingDrawer = styled(SlidingDrawer)`
  width: 100%;
`

/**
 * The "hamburger menu" that replaces the "Profile and Settings" button for
 * sm screen devices. This component is only rendered for sm screen devices.
 */
const NavDrawerMenu: React.FC = () => {
  const hostServices = useHostServices()
  const { session, globalnav } = hostServices
  const isSmDown = useMediaSize('SmDown')
  const logout = getLogout(hostServices)
  const { isHidden } = globalnav
  const isAuthenticated = session?.status === 'Authenticated'

  const targetRef = useRef<HTMLAnchorElement>(null)
  const triggerRef = useRef<HTMLButtonElement>(null)

  const [isMenuOpen, setMenuOpen] = useState(false)

  return (
    <>
      {!isHidden && isAuthenticated && isSmDown && (
        <>
          <NavDrawerTrigger
            ref={triggerRef}
            onClick={(): void => setMenuOpen(prev => !prev)}
            isMenuOpened={isMenuOpen}
          />
          <StyledSlidingDrawer
            show={isMenuOpen}
            isAriaModal
            enterFrom="left"
            background="plum"
            disablePadding
            closeButtonColor="white"
            dismissOnRouteChange
            onClickAway={(close: VoidFunction): void => {
              close()
            }}
            onOpened={(): void => targetRef.current?.focus()}
            onClosed={(reason): void => {
              if (reason !== 'location-change') triggerRef.current?.focus()
              setMenuOpen(false)
            }}
          >
            <NavDrawerContent targetRef={targetRef} onLogout={logout} />
          </StyledSlidingDrawer>
        </>
      )}
    </>
  )
}

export { NavDrawerMenu }
