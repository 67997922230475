import React, { useEffect, useRef, useState } from 'react'
import { get, useMediaSize } from '@ally/metronome-ui'

import SlidingDrawer from '@ally/metronome-sliding-drawer'
import { navHeight } from '@ally/next-layout'
import styled from 'styled-components'

import { ProfileMenuTrigger } from './ProfileMenuTrigger'
import { ProfileMenuContent } from './ProfileMenuContent'
import { useHostServices } from '../../HostServices'

const StyledSlidingDrawer = styled(SlidingDrawer)`
  width: 100%;
  ${get.media.up('md')} {
    width: 500px;
  }
`
/**
 * The <ProfileMenu /> component is the "right side" slide out menu that appears
 * when the user clicks the "Profile and Settings" button on the <Header />. It
 * contains a button to logout and links to change user profile settings,
 * account information, etc.
 */
const ProfileMenu: React.FC = () => {
  const targetRef = useRef<HTMLSpanElement | HTMLButtonElement>(null)
  const triggerRef = useRef<HTMLButtonElement>(null)
  const isSmDown = useMediaSize('SmDown')

  const [drawerState, setDrawerState] = useState('closed')
  const [isMenuOpen, setMenuOpen] = useState(false)

  const { session, globalnav } = useHostServices()
  const isAuthenticated = session?.status === 'Authenticated'
  const { isHidden } = globalnav

  // Watch for changes in the above-app-portal so that we can recalculate the sliding drawer
  const [topOffset, setTopOffset] = useState(0)
  useEffect(() => {
    const aboveAppPortal = document.getElementById('above-app-portal')

    const resizeHandler = (): void => {
      const portalHeight = aboveAppPortal?.offsetHeight ?? 0
      const newOffset = navHeight.num + portalHeight
      setTopOffset(newOffset)
    }

    resizeHandler()

    const resizeObserver = new ResizeObserver(resizeHandler)

    if (aboveAppPortal) {
      resizeObserver.observe(aboveAppPortal)
    }

    return (): void => {
      resizeObserver.disconnect()
    }
  }, [])
  if (isAuthenticated && !isHidden) {
    return (
      <>
        <ProfileMenuTrigger
          ref={triggerRef}
          onClick={(): void => setMenuOpen(prev => !prev)}
          isMenuOpened={drawerState === 'opened'}
          isMenuOpening={drawerState === 'opening'}
        />
        <StyledSlidingDrawer
          topOffset={isSmDown ? 0 : topOffset}
          show={isMenuOpen}
          isAriaModal
          hideCloseButton
          dismissOnRouteChange
          onOpened={(): void => targetRef.current?.focus()}
          onClosed={(): void => setMenuOpen(false)}
          onClosing={(reason): void => {
            if (reason !== 'location-change') triggerRef.current?.focus()
          }}
          onStateChange={setDrawerState}
        >
          <ProfileMenuContent
            focusRef={targetRef}
            onClose={(): void => setMenuOpen(false)}
          />
        </StyledSlidingDrawer>
      </>
    )
  }

  return null
}

export { ProfileMenu }
