import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from 'react'
import { useGlobalLocation } from '@ally/federator'
import { noop, getJSONSessionStorage } from '@ally/utilitarian'
import { getEnabledCoachmarks } from '@ally/whats-new-content'
import { useCoachmarkDataFetch } from './use-coachmark-data-fetch'
import { useCoachmarkUpdater } from './use-coachmark-updater'
import { combineCoachmarks, Coachmark, CoachmarkData } from './utils'
import { sessionFirstDashboardVisitKey } from '../../constants'
import { useHostServices } from '../../HostServices'
import { useLobs, useIsOnly } from '../../hooks'
import { useWhatsNewImpression } from './useWhatsNewImpression'

type CoachmarkInteractionFunc = (
  interactedCoachmarkId: number | string | null,
) => Promise<void>

type CoachmarkCompletedFunc = (
  completedCoachmarkId: number | string | null,
) => Promise<void>

export type CoachmarksContext = {
  coachmarks: Coachmark[]
  onCoachmarkInteraction: CoachmarkInteractionFunc
  onCoachmarkCompleted: CoachmarkCompletedFunc
  openMenuOnMount: boolean
}

const CoachmarksContext = React.createContext({
  coachmarks: [] as Coachmark[],
  onCoachmarkInteraction: noop as CoachmarkInteractionFunc,
  onCoachmarkCompleted: noop as CoachmarkCompletedFunc,
  openMenuOnMount: false,
})

export const useCoachmarks = (): CoachmarksContext =>
  useContext(CoachmarksContext)

export const CoachmarksProvider: React.FC = ({ children }) => {
  const { hostData, session, featureFlags } = useHostServices()

  const allyUserRole = useLobs()

  const services = featureFlags.bank.variation

  const { pathname } = useGlobalLocation()

  const fetchCoachmarkData = useCoachmarkDataFetch()
  const updateCoachmarkUtil = useCoachmarkUpdater()

  const guid = useMemo(() => session.data?.guid, [session.data])
  /**
   * We're separating these three pieces of state so that the fetch and update
   * coachmark api calls have a place to store their return values without
   * overwriting the displayed coachmarks. The displayed coachmarks are
   * re-filtered every time one of its dependent values change.
   */
  const [coachmarksData, setCoachmarksData] = useState<CoachmarkData[]>([])
  const [combinedCoachmarks, setCombinedCoachmarks] = useState<Coachmark[]>([])
  const [displayedCoachmarks, setDisplayedCoachmarks] = useState<Coachmark[]>(
    [],
  )

  const enabledCoachmarks = useMemo(
    () =>
      services
        ? getEnabledCoachmarks({
            allyUserRole,
            hostData,
            pathname,
            ffVariation: services,
            session,
          })
        : [],
    [hostData, pathname, services, allyUserRole, session],
  )

  useEffect(() => {
    setCombinedCoachmarks(combineCoachmarks(coachmarksData, enabledCoachmarks))
  }, [coachmarksData, enabledCoachmarks])

  useEffect(() => {
    setDisplayedCoachmarks(
      combinedCoachmarks.filter(coachmark =>
        enabledCoachmarks.find(
          enabled => enabled.name === coachmark.content.name,
        ),
      ),
    )
  }, [combinedCoachmarks, enabledCoachmarks])

  const hasEnabledCoachmarks = enabledCoachmarks.length > 0
  const isAutoOnly = useIsOnly('auto')

  useEffect((): void => {
    // the second check ensure the api doesn't pass back the string "null"
    // this isn't preventative. it actually passes back the string "null"
    if (isAutoOnly || !guid || guid === 'null' || !hasEnabledCoachmarks) {
      return
    }
    fetchCoachmarkData({ guid }).then(setCoachmarksData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasEnabledCoachmarks, guid, isAutoOnly])

  const updateCoachmark = useCallback(
    async (coachmark: Coachmark): Promise<void> => {
      setCombinedCoachmarks(
        await updateCoachmarkUtil({
          initialCoachmarkState: combinedCoachmarks,
          coachmarkToUpdate: coachmark,
          guid,
        }),
      )
    },
    [guid, combinedCoachmarks, updateCoachmarkUtil, setCombinedCoachmarks],
  )

  const onCoachmarkCompleted = useCallback(
    async (coachmarkId: number | string | null) => {
      const coachmark = displayedCoachmarks.find(
        mark => mark.data.id === coachmarkId,
      )

      if (coachmark) {
        const { content, data } = coachmark

        // no reason to update this value (already set to true)
        if (data.completed) return

        await updateCoachmark({
          data: { ...data, completed: true },
          content,
        })
      }
    },
    [displayedCoachmarks, updateCoachmark],
  )

  const onCoachmarkInteraction = useCallback(
    async (selectedCoachmarkId: number | string | null) => {
      await displayedCoachmarks.forEach(async coachmark => {
        if (coachmark.data.id !== selectedCoachmarkId) {
          const { content, data } = coachmark

          // no reason to update this value (already set to true)
          if (data.ignored) return

          await updateCoachmark({
            data: { ...data, ignored: true },
            content,
          })
        }
      })
    },
    [displayedCoachmarks, updateCoachmark],
  )

  const isFirstDashboardVisit =
    getJSONSessionStorage<boolean>(sessionFirstDashboardVisitKey) === true

  const openMenuOnMount =
    isFirstDashboardVisit &&
    displayedCoachmarks.some(({ data }) => !data.completed && !data.ignored)

  useWhatsNewImpression({ automaticOpen: openMenuOnMount, displayedCoachmarks })

  return (
    <CoachmarksContext.Provider
      value={{
        coachmarks: displayedCoachmarks,
        onCoachmarkInteraction,
        onCoachmarkCompleted,
        openMenuOnMount,
      }}
    >
      {children}
    </CoachmarksContext.Provider>
  )
}
