import React, { useEffect, useMemo } from 'react'
import { useHistory, Link as ReactRouterLink } from 'react-router-dom'
import styled, { css, ThemeProvider } from 'styled-components'
import { MenuItem } from 'react-aria-menubutton'
import { Box, Link as MuiLink, muiBankTheme } from '@ally/metronome-ui'
import { accounts as uuipAccounts } from '@unified-ui/ally-next-host'
import { RelationshipStatusTypes } from '@ally/transmitigator'

import { Domains } from '@ally/data-types-host'
import { trackCCAccount, getBankruptcyAcceptedSessionProp } from '../../utils'
import DetailBar from './DetailBar'
import CreditCardDetailBar from './CreditCardDetailBar'
import Error from './Error'
import { useCustomization } from './use-customization'
import { isRestrictedStatus, mapColors, accountCardAllyTM } from './utils'
import StaleDataNotice from './StaleDataNotice'
import { useHostServices } from '../../HostServices'
import { useAccountStatus, useOutageCheck } from '../../hooks'

const mapInvestAccount = (
  account: uuipAccounts.types.AccountForDropdown,
  inNavDrawer?: boolean,
): React.ReactElement => {
  const { id, type } = account
  const accountColor = mapColors(type)
  return (
    <DetailBar
      account={account}
      accountColor={accountColor}
      key={`detail-${id}`}
      inNavDrawer={inNavDrawer}
      invest
    />
  )
}

const mapDepositAccount = (
  account: uuipAccounts.types.AccountForDropdown,
  inNavDrawer?: boolean,
): React.ReactElement => {
  const { type, indicator, id } = account
  const accountColor = mapColors(type, indicator?.retirement)

  return (
    <DetailBar
      account={account}
      accountColor={accountColor}
      key={`detail-${id}`}
      inNavDrawer={inNavDrawer}
    />
  )
}

const linkStyles = css`
  text-decoration: none;
  a {
    width: 100%;
    justify-content: flex-start;
    text-decoration: none;
  }
`

const StyledLink = styled(MuiLink)`
  ${linkStyles}
`

const StyledMenuItem = styled(MenuItem)`
  ${linkStyles}
`

const mapAccountCards = <T extends uuipAccounts.types.AccountForDropdown>(
  accounts: T[] | null,
  getAccountUrl: (account: T) => string,
  mapper: (account: T, inNavDrawer?: boolean) => React.ReactElement,
  history: ReturnType<typeof useHistory>,
  inNavDrawer?: boolean,
): React.ReactElement[] | undefined => {
  return accounts?.map?.(account => {
    const accountUrl = getAccountUrl(account)
    const content = mapper(account, inNavDrawer)

    const { featureFlags } = useHostServices()

    const isCreditCardEnabled = featureFlags.variation(
      'FF_credit-card-snapshot',
      false,
    )

    const isCCAccount =
      isCreditCardEnabled && account.lob === uuipAccounts.types.AccountLOB.CC

    const onRouterLinkClick = (
      event: React.MouseEvent<HTMLAnchorElement>,
    ): void => {
      event.preventDefault()
      history.push(accountUrl)
    }

    const accountId = account.id

    if (isCreditCardEnabled) {
      // We don't want to treat a CLOSED account as restricted if it has a balance
      const isClosedWithValue =
        account.status === uuipAccounts.types.AccountStatus.CLOSED &&
        typeof account.balance?.current === 'number'

      const isRestrictedCCStatus =
        account.lob === uuipAccounts.types.AccountLOB.CC &&
        isRestrictedStatus(account.status) &&
        !isClosedWithValue

      if (isRestrictedCCStatus) {
        if (inNavDrawer) {
          return (
            <Box
              key={accountId}
              tabIndex={0}
              data-allytmln={accountCardAllyTM(account)}
            >
              {content}
            </Box>
          )
        }
        return (
          <MenuItem
            key={accountId}
            tabIndex={0}
            data-testid={`account-dropdown-${account.accountTypeLabel}`}
            onClick={(): void => trackCCAccount(account)}
            data-allytmln={accountCardAllyTM(account)}
          >
            {content}
          </MenuItem>
        )
      }
    }

    if (inNavDrawer) {
      return (
        <StyledLink unsupportedContent={content}>
          <ReactRouterLink
            to={accountUrl}
            key={accountId}
            tabIndex={0}
            data-allytmln={accountCardAllyTM(account)}
          />
        </StyledLink>
      )
    }

    return (
      <StyledMenuItem
        tag="a"
        href={accountUrl}
        onClick={(e: React.MouseEvent<HTMLAnchorElement>): void => {
          if (isCCAccount) {
            trackCCAccount(account)
          }
          onRouterLinkClick(e)
        }}
        data-testid={`account-dropdown-${account.accountTypeLabel}`}
        key={accountId}
        tabIndex={0}
        data-allytmln={accountCardAllyTM(account)}
      >
        {content}
      </StyledMenuItem>
    )
  })
}

const MaybeInvestAccountCards: React.FC<{ inNavDrawer?: boolean }> = ({
  inNavDrawer,
}) => {
  const history = useHistory()
  const { featureFlags, hostData, session } = useHostServices()
  const accountsData = hostData[Domains.AFG_ACCOUNTS].data
  const customizationData = hostData[Domains.CUSTOMIZATION].data
  const { data } = session
  const { isInvestOutage, isWealthOutage } = useOutageCheck()
  const { hasStaleInvestAccounts } = useAccountStatus()

  const isPersonalAdviceEnabled = featureFlags.variation(
    'FF_personal-advisor',
    false,
  )

  const isRoboRebrandEnabled = featureFlags.variation(
    'FF_robo-portfolio-rebrand',
    false,
  )

  let sortedAccounts = uuipAccounts.selectors.invest.getAllForDropdown(
    accountsData,
    customizationData,
  )

  if (isRoboRebrandEnabled) {
    /*
      Once the Robo Portfolio => Automated Investing initiative has been released and we are ready
      to remove the FF, the following code should be removed and appropriately updated.

      We didn't update libs at this moment as we still need the ability to enable/disable
      the feature flag. So we are making the conversion here until the initiative is complete.
    */
    sortedAccounts = sortedAccounts.map(account =>
      account.accountTypeLabel ===
      uuipAccounts.types.AccountTypeLabel.ROBO_PORTFOLIO
        ? {
            ...account,
            accountTypeLabel: 'AUTOMATED INVESTING' as uuipAccounts.types.AccountTypeLabel,
          }
        : account,
    )
  }

  const wealthAccounts = sortedAccounts.filter(
    acct => acct.type === uuipAccounts.types.AccountType.INVW,
  )

  const allAccountsClosed = wealthAccounts.length
    ? wealthAccounts.every(acc => acc.status.toLowerCase() === 'closed')
    : false

  const wealthTotalBalance = wealthAccounts.reduce(
    (accum, acct) => accum + (acct.value?.total ?? 0),
    0,
  )
  const wealthSummary: uuipAccounts.types.AccountForDropdown = {
    id: '',
    accountNumber: '',
    lob: uuipAccounts.types.AccountLOB.WEALTH,
    status: allAccountsClosed
      ? uuipAccounts.types.AccountStatus.CLOSED
      : uuipAccounts.types.AccountStatus.ACTIVE,
    type: uuipAccounts.types.AccountType.INVW,
    name: 'Wealth Overview',
    nickName: 'Wealth Overview',
    institution: 'ally',
    accountTypeLabel: isPersonalAdviceEnabled
      ? uuipAccounts.types.AccountTypeLabel.PERSONAL_ADVICE
      : uuipAccounts.types.AccountTypeLabel.WEALTH_MANAGEMENT,
    accountBalanceToShow: wealthTotalBalance,
  }

  // This reduces wealth accounts to one single account in invest accounts array
  let hasCalculatedWealthOverview = false
  const reducedSortedAccounts = sortedAccounts.reduce((accounts, account) => {
    if (
      account.type === uuipAccounts.types.AccountType.INVW &&
      !hasCalculatedWealthOverview
    ) {
      hasCalculatedWealthOverview = true
      return [...accounts, wealthSummary]
    }
    if (account.type !== uuipAccounts.types.AccountType.INVW) {
      return [...accounts, account]
    }
    return accounts
  }, [] as uuipAccounts.types.AccountForDropdown[])

  const clientStatuses: RelationshipStatusTypes[] = [
    'CLIENT_UNFUNDED',
    'CLIENT_FUNDED',
  ]
  const wealthUserStatus = data?.relationships?.invest?.wealth?.status
  // make sure user does not have a Prospect relationship
  const isWealthClient = !!(
    wealthUserStatus && clientStatuses.includes(wealthUserStatus)
  )

  if (isInvestOutage || (isWealthClient && isWealthOutage)) {
    return <Error accountType="Investments" inNavDrawer={inNavDrawer} />
  }

  const getAccountURL = (
    account: uuipAccounts.types.AccountForDropdown,
  ): string => {
    const isINVW = account.type === uuipAccounts.types.AccountType.INVW
    const isINVS = account.type === uuipAccounts.types.AccountType.INVS
    const subPath = isINVS ? 'accounts/holdings-balances' : 'dashboard'
    if (hasStaleInvestAccounts) {
      return '/error'
    }
    return isINVW
      ? '/ext-wealth/wealth-overview'
      : `/ext-invest-live/${subPath}/${account.id}`
  }

  return (
    <>
      {hasStaleInvestAccounts && <StaleDataNotice inNavDrawer={inNavDrawer} />}
      {mapAccountCards<uuipAccounts.types.AccountForDropdown>(
        reducedSortedAccounts,
        getAccountURL,
        mapInvestAccount,
        history,
        inNavDrawer,
      )}
    </>
  )
}

const MaybeDepositAccountCards: React.FC<{ inNavDrawer?: boolean }> = ({
  inNavDrawer,
}) => {
  const history = useHistory()
  const { featureFlags, hostData } = useHostServices()
  const accountsData = hostData[Domains.AFG_ACCOUNTS].data
  const customizationData = hostData[Domains.CUSTOMIZATION].data
  const { isBankOutage } = useOutageCheck()
  const isProductNameChangeEnabled = featureFlags.variation(
    'FF_product-name-change',
    false,
  )

  let depositDropdownAccounts = uuipAccounts.selectors.deposit.getAllForDropdown(
    accountsData,
    customizationData,
  )

  if (isProductNameChangeEnabled) {
    /*
      Product Name Change
      https://jira.int.ally.com/browse/WEB-58521

      Once the Product Name Change initiative has been released, approved, and we are ready
      to remove the FF, the following code should be removed since the deposit account type names
      will be returned automatically as CHECKING when the `AccountTypeToLabel` object is updated.

      We didn't update that object at this moment as we still need the ability to enable/disable
      the ProductNameChangeEnabled feature flag. So we are making the conversion here until the initiative is complete.
    */
    depositDropdownAccounts = depositDropdownAccounts.map(account =>
      account.accountTypeLabel ===
      uuipAccounts.types.AccountTypeLabel.INTEREST_CHECKING
        ? {
            ...account,
            accountTypeLabel: uuipAccounts.types.AccountTypeLabel.CHECKING,
          }
        : account,
    )
  }

  const { hasStaleBankAccounts } = useAccountStatus()

  if (isBankOutage) {
    return <Error accountType="Bank Accounts" inNavDrawer={inNavDrawer} />
  }

  const getAccountURL = ({
    id,
    type,
  }: uuipAccounts.types.AccountForDropdown): string => {
    const DepositAccountLink = `/account/${id}/details`

    if (hasStaleBankAccounts) {
      return '/error'
    }
    return DepositAccountLink
  }

  return (
    <>
      {hasStaleBankAccounts && depositDropdownAccounts.length > 0 && (
        <StaleDataNotice inNavDrawer={inNavDrawer} />
      )}
      {mapAccountCards<uuipAccounts.types.AccountForDropdown>(
        depositDropdownAccounts,
        getAccountURL,
        mapDepositAccount,
        history,
        inNavDrawer,
      )}
    </>
  )
}

const mapMortgageAccount = (
  account: uuipAccounts.types.AccountForDropdown,
  inNavDrawer?: boolean,
): React.ReactElement => {
  const { type, id } = account
  const accountColor = mapColors(type)

  return (
    <DetailBar
      account={account}
      accountColor={accountColor}
      key={`detail-${id}`}
      inNavDrawer={inNavDrawer}
    />
  )
}

const MaybeMortgageAccountCards: React.FC<{
  inNavDrawer?: boolean
}> = ({ inNavDrawer }) => {
  const history = useHistory()
  const { hostData } = useHostServices()
  const accountsData = hostData[Domains.AFG_ACCOUNTS].data
  const customizationData = hostData[Domains.CUSTOMIZATION].data

  const { isMortgageOutage } = useOutageCheck()

  const sortedAccounts = uuipAccounts.selectors.mortgage.getAllForDropdown(
    accountsData,
    customizationData,
  )

  const getAccountURL = ({
    accountNumber,
  }: uuipAccounts.types.AccountForDropdown): string => {
    return `/sso/home-loans?id=${accountNumber}`
  }

  if (isMortgageOutage) {
    return <Error accountType="home loans" inNavDrawer={inNavDrawer} />
  }

  return (
    <>
      {mapAccountCards<uuipAccounts.types.AccountForDropdown>(
        sortedAccounts,
        getAccountURL,
        mapMortgageAccount,
        history,
        inNavDrawer,
      )}
    </>
  )
}

const mapAutoAccount = (
  account: uuipAccounts.types.AccountForDropdown,
  inNavDrawer?: boolean,
): React.ReactElement => {
  const { type, id } = account
  const accountColor = mapColors(type)

  window.allytm.event('customEvent', {
    tagName: 'vehicleCard',
    allytmln: 'account-dropdown-VEHICLE FINANCING',
  })

  return (
    <DetailBar
      account={account}
      accountColor={accountColor}
      key={`detail-${id}`}
      inNavDrawer={inNavDrawer}
    />
  )
}

const MaybeAutoAccountCards: React.FC<{
  inNavDrawer?: boolean
}> = ({ inNavDrawer }) => {
  const history = useHistory()
  const { featureFlags, hostData } = useHostServices()
  const accountsData = hostData[Domains.AFG_ACCOUNTS].data
  const isAutoEnabled = featureFlags.variation('FF_SPOG_WEB_UI', true)
  const {
    hasStaleAutoAccounts,
    isAutoScheduledMaintenance,
  } = useAccountStatus()
  const isBankruptcyAccepted = getBankruptcyAcceptedSessionProp()

  useEffect(() => {
    if (isAutoScheduledMaintenance)
      window.allytm.event(
        'customError',
        "We can't show your vehicle accounts right now.",
      )
  }, [isAutoScheduledMaintenance])

  useEffect(() => {
    if (hasStaleAutoAccounts)
      window.allytm.event(
        'customError',
        "We've hit a snag when trying to update your account information. A fix is in the works.",
      )
  }, [hasStaleAutoAccounts])

  const sortedAccounts = useMemo(() => {
    return uuipAccounts.selectors.auto.getAllForDropdown(
      accountsData,
      isBankruptcyAccepted,
    )
  }, [hostData, isBankruptcyAccepted])

  if (!isAutoEnabled) {
    return <></>
  }

  if (isAutoScheduledMaintenance) {
    return <Error accountType="vehicle accounts" inNavDrawer={inNavDrawer} />
  }

  const getAccountURL = ({
    id,
  }: uuipAccounts.types.AccountForDropdown): string => {
    return `/sso/auto/accounts/${id}`
  }

  return (
    <>
      {hasStaleAutoAccounts && sortedAccounts.length > 0 && (
        <StaleDataNotice inNavDrawer={inNavDrawer} />
      )}
      {mapAccountCards<uuipAccounts.types.AccountForDropdown>(
        sortedAccounts,
        getAccountURL,
        mapAutoAccount,
        history,
        inNavDrawer,
      )}
    </>
  )
}

const mapCreditCardAccount = (
  account: uuipAccounts.types.AccountForDropdown,
  inNavDrawer?: boolean,
): React.ReactElement => {
  const { type, id } = account
  const accountColor = mapColors(type)

  return (
    <CreditCardDetailBar
      account={account}
      accountColor={accountColor}
      key={`detail-${id}`}
      inNavDrawer={inNavDrawer}
    />
  )
}

const MaybeCreditCardAccountCards: React.FC<{
  inNavDrawer?: boolean
}> = ({ inNavDrawer }) => {
  const history = useHistory()
  const { featureFlags, hostData } = useHostServices()
  const accountsData = hostData[Domains.AFG_ACCOUNTS].data
  const customizationData = hostData[Domains.CUSTOMIZATION].data
  const { isCreditCardOutage } = useOutageCheck()
  const { hasStaleCCAccounts } = useAccountStatus()
  const isCreditCardEnabled = featureFlags.variation(
    'FF_credit-card-snapshot',
    false,
  )

  const isCreditCardMigrationMessageEnabled = featureFlags.variation(
    'FF_credit-card-migration-message',
    false,
  )

  if (!isCreditCardEnabled) {
    return <></>
  }

  if (isCreditCardOutage) {
    return (
      <Error accountType="credit card accounts" inNavDrawer={inNavDrawer} />
    )
  }

  const sortedAccounts = uuipAccounts.selectors.creditCard.getAllForDropdown(
    accountsData,
    customizationData,
  )
  const getAccountURL = ({
    id,
  }: uuipAccounts.types.AccountForDropdown): string => {
    return `/sso/credit-card/Account/Overview?accountProxyId=${id}`
  }

  return (
    <>
      {hasStaleCCAccounts && sortedAccounts.length > 0 && (
        <StaleDataNotice
          inNavDrawer={inNavDrawer}
          isCreditCardMigrationMessageEnabled={
            isCreditCardMigrationMessageEnabled
          }
        />
      )}
      {mapAccountCards<uuipAccounts.types.AccountForDropdown>(
        sortedAccounts,
        getAccountURL,
        mapCreditCardAccount,
        history,
        inNavDrawer,
      )}
    </>
  )
}

const AccountsDetails: React.FC<{
  inNavDrawer?: boolean
  hasMaxHeight?: boolean
}> = ({ inNavDrawer, hasMaxHeight = true }) => {
  const {
    investOrder,
    depositOrder,
    mortgageOrder,
    autoOrder,
    creditCardOrder,
  } = useCustomization()

  const sortedAccountCards = useMemo(
    () =>
      [
        {
          component: (
            <MaybeInvestAccountCards inNavDrawer={inNavDrawer} key="invest" />
          ),
          order: investOrder,
        },
        {
          component: (
            <MaybeDepositAccountCards inNavDrawer={inNavDrawer} key="deposit" />
          ),
          order: depositOrder,
        },
        {
          component: (
            <MaybeCreditCardAccountCards
              inNavDrawer={inNavDrawer}
              key="creditCard"
            />
          ),
          order: creditCardOrder,
        },
        {
          component: (
            <MaybeAutoAccountCards inNavDrawer={inNavDrawer} key="auto" />
          ),
          order: autoOrder,
        },
        {
          component: (
            <MaybeMortgageAccountCards
              inNavDrawer={inNavDrawer}
              key="mortgage"
            />
          ),
          order: mortgageOrder,
        },
      ].sort((a, b) => a.order - b.order),
    [
      investOrder,
      depositOrder,
      mortgageOrder,
      autoOrder,
      creditCardOrder,
      inNavDrawer,
    ],
  )

  return (
    // we need an additional ThemeProvider here to override the navigationMenuTheme
    // colorMode that the NAVIGATION Accordion uses. It turns all of the text white.
    <ThemeProvider theme={muiBankTheme}>
      <Box
        overflowY="scroll"
        display="flex"
        flexDirection="column"
        borderTopWidth="1px"
        borderTopStyle="solid"
        borderTopColor="slate-2"
        maxHeight={hasMaxHeight ? '285px' : undefined}
        data-testid="accounts-dropdown-container"
      >
        {sortedAccountCards.map(({ component }) => component)}
      </Box>
    </ThemeProvider>
  )
}

export { AccountsDetails }
