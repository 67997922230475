import * as React from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { TextBody, ScreenReaderOnly } from '@ally/metronome-ui'
import { accounts } from '@unified-ui/ally-next-host'

export type CreditCardNames =
  | 'Ally Platinum Credit Card'
  | 'Ally Everyday Cash Back Credit Card'
  | 'Ally Unlimited Cash Back Credit Card'

type MetronomeColor = keyof DefaultTheme['colors']

type AccountTypes = Extract<
  accounts.types.AccountType,
  | accounts.types.AccountType.DDA
  | accounts.types.AccountType.MMA
  | accounts.types.AccountType.SDA
  | accounts.types.AccountType.CMG
  | accounts.types.AccountType.CDA
  | accounts.types.AccountType.INVS
  | accounts.types.AccountType.INVA
  | accounts.types.AccountType.INVW
  | accounts.types.AccountType.MORTGAGE
  | accounts.types.AccountType.HOME_EQUITY
  | accounts.types.AccountType.PLATINUM
  | accounts.types.AccountType.EVERYDAY
  | accounts.types.AccountType.UNLIMITED
  | accounts.types.AccountType.LOAN
  | accounts.types.AccountType.OPERATING_LEASE
  | accounts.types.AccountType.FINANCE_LEASE
  | accounts.types.AccountType.HIRE_PURCHASE
>

const depositColorMap: Record<AccountTypes, MetronomeColor> = {
  DDA: 'dda',
  MMA: 'mma',
  SDA: 'sda',
  CMG: 'cmg',
  CDA: 'cda',
  INVS: 'inv',
  INVA: 'inva',
  INVW: 'invw',
  MORTGAGE: 'mortgage',
  HOME_EQUITY: 'mortgage',
  PLATINUM: 'blue-900',
  EVERYDAY: 'blue-300',
  UNLIMITED: 'blue-700',
  LOAN: 'auto',
  OPERATING_LEASE: 'auto',
  FINANCE_LEASE: 'auto',
  HIRE_PURCHASE: 'auto',
}

export const mapColors = (
  accountType: accounts.types.AccountType,
  retirementIndicator?: boolean,
): MetronomeColor => {
  if (retirementIndicator) {
    return 'ira'
  }

  return depositColorMap[accountType as AccountTypes]
}

export const isRestrictedStatus = (
  status: accounts.types.AccountStatus,
): boolean =>
  ['BANKRUPT', 'FROZEN_OR_FRAUD', 'CLOSED', 'STOLEN', 'LOST'].includes(status)

export const getStatusDescription = (
  statusDescription?: string,
  nickName?: string,
): string | null => {
  switch (statusDescription) {
    case 'PAST_DUE':
    case 'OVERDUE':
      return `Looks like you missed a payment for your ${nickName}.`
    case 'OVER_LIMIT':
      return "You're over your credit limit."
    default:
      return null
  }
}

export const getStatus = (
  status: accounts.types.AccountStatus,
): string | null => {
  switch (status) {
    case 'BANKRUPT':
      return "You can't access this account."
    case 'FROZEN_OR_FRAUD':
    case 'STOLEN':
    case 'LOST':
      return "You can't access this account right now."
    case 'CLOSED':
      return 'Account closed'
    default:
      return null
  }
}

export enum ValueHeading {
  ACCOUNT_VALUE = 'ACCOUNT VALUE',
  AVAILABLE = 'AVAILABLE',
  CURRENT = 'CURRENT',
  CURRENT_BALANCE = 'CURRENT BALANCE',
  CLOSED = 'CLOSED',
  PAYMENT_DUE = 'PAYMENT DUE',
  PAID_IN_FULL = 'Paid In Full',
}

const getLabelHeadings = (
  label: accounts.types.AccountTypeLabel,
): ValueHeading | null => {
  switch (label) {
    case accounts.types.AccountTypeLabel.WEALTH_MANAGEMENT:
    case accounts.types.AccountTypeLabel.ROBO_PORTFOLIO:
    case accounts.types.AccountTypeLabel.SELF_DIRECTED:
    case accounts.types.AccountTypeLabel.PERSONAL_ADVICE:
    case accounts.types.AccountTypeLabel.AUTOMATED_INVESTING:
      return ValueHeading.ACCOUNT_VALUE
    case accounts.types.AccountTypeLabel.INTEREST_CHECKING:
    case accounts.types.AccountTypeLabel.CHECKING:
    case accounts.types.AccountTypeLabel.SAVINGS:
      return ValueHeading.AVAILABLE
    case accounts.types.AccountTypeLabel.CDs:
      return ValueHeading.CURRENT
    case accounts.types.AccountTypeLabel.MORTGAGE:
    case accounts.types.AccountTypeLabel.HOME_EQUITY:
    case accounts.types.AccountTypeLabel.PLATINUM:
    case accounts.types.AccountTypeLabel.EVERYDAY:
    case accounts.types.AccountTypeLabel.UNLIMITED:
    case accounts.types.AccountTypeLabel.VEHICLE_FINANCING:
      return ValueHeading.PAYMENT_DUE
    default:
      return null
  }
}

type AdditionalValueHeadingProps = {
  isCreditCardEnabled?: boolean
  statusDescription?: string
}

type GetValueHeadingType = Pick<
  accounts.types.AccountForDropdown,
  'accountTypeLabel' | 'status' | 'lob' | 'paidInFullOrZeroBalance'
> &
  AdditionalValueHeadingProps

const StyledPaidInFullTextBody = styled(TextBody)`
  ${({ theme: { colors } }): string => `
    color: ${colors['success-green']};
    text-transform: capitalize;
  `};
`

export const getValueHeading = ({
  accountTypeLabel,
  status,
  statusDescription,
  lob,
  isCreditCardEnabled,
  paidInFullOrZeroBalance,
}: GetValueHeadingType): React.ReactNode => {
  if (isCreditCardEnabled && lob === accounts.types.AccountLOB.CC) {
    if (
      isRestrictedStatus(status) ||
      getStatusDescription(statusDescription) ||
      statusDescription === 'NOT_ACTIVATED'
    ) {
      return (
        <TextBody tag="p" size="xs" weight="bold">
          {ValueHeading.CURRENT_BALANCE}
        </TextBody>
      )
    }
  }

  if (status.toLowerCase() === 'closed') {
    return (
      <TextBody tag="p" size="sm" type="error" weight="bold">
        {ValueHeading.CLOSED}
      </TextBody>
    )
  }

  // if auto account is paid off, show 'Paid In Full' as heading
  if (paidInFullOrZeroBalance) {
    return (
      <StyledPaidInFullTextBody tag="p" size="xs">
        {ValueHeading.PAID_IN_FULL}
      </StyledPaidInFullTextBody>
    )
  }

  const valueHeading = getLabelHeadings(accountTypeLabel)

  if (valueHeading) {
    return (
      <TextBody tag="p" size="xs" weight="bold">
        {valueHeading}
      </TextBody>
    )
  }

  return null
}

export function getCreditCardName(
  account: accounts.types.Account,
): CreditCardNames {
  switch (account.type) {
    case accounts.types.AccountType.UNLIMITED:
      return 'Ally Unlimited Cash Back Credit Card'
    case accounts.types.AccountType.EVERYDAY:
      return 'Ally Everyday Cash Back Credit Card'
    case accounts.types.AccountType.PLATINUM:
      return 'Ally Platinum Credit Card'
    default:
      return 'Ally Everyday Cash Back Credit Card'
  }
}

export function getDashDash(assistiveText: string): React.ReactElement {
  return (
    <TextBody tag="span" size="md">
      <span aria-hidden="true">- -</span>
      <ScreenReaderOnly>{assistiveText}</ScreenReaderOnly>
    </TextBody>
  )
}

const accountTypeAllyTM = {
  DDA: 'deposit-interestCheckingAccount-DDA-accountsDropdown',
  CDA: 'deposit-cdAccount-CDA-accountsDropdown',
  SDA: 'deposit-savingsAccount-SDA-accountsDropdown',
  MMA: 'deposit-savingsAccount-MMA-accountsDropdown',
  CMG: 'deposit-savingsAccount-CMG-accountsDropdown',
  INVS: 'invest-selfDirectedInvestAccount-INVS-accountsDropdown',
  INVA: 'invest-roboPortfolioInvestAccount-INVA-accountsDropdown',
  INVW: 'wealth-WealthOverview-accountsDropdown',
  EVERYDAY: 'creditCard-creditCardAccount-EVERYDAY-Card-accountsDropdown',
  PLATINUM: 'creditCard-creditCardAccount-PLATINUM-Card-accountsDropdown',
  UNLIMITED: 'creditCard-creditCardAccount-UNLIMITED-Card-accountsDropdown',
  LOAN: 'auto-autoAccount-LOAN-accountsDropdown',
  OPERATING_LEASE: 'auto-autoAccount-OPERATING-LEASE-accountsDropdown',
  FINANCE_LEASE: 'auto-autoAccount-FINANCE-LEASE-accountsDropdown',
  HIRE_PURCHASE: 'auto-autoAccount-HIRE-PURCHASE-accountsDropdown',
  MORTGAGE: 'mortgage-mortgageAccount-MORTGAGE-accountsDropdown',
  HOME_EQUITY: 'mortgage-mortgageAccount-HOME-EQUITY-accountsDropdown',
  // Not sure what LOB these account types are under, or if they're still active types
  // Not sure what LOB these account types are under, or if they're still active types
  MARGIN: 'margin-MarginAccount-accountsDropdown',
  TRUST: 'trust-trustAccount-accountsDropdown',
}

export function accountCardAllyTM(account: accounts.types.Account): string {
  const hasSepIraAccount = account.meta?.retirementPlanType === 'SEP'
  const hasRothIraAccount = account.meta?.retirementPlanType === 'Roth'
  const hasTraditionalIraAccount =
    account.meta?.retirementPlanType === 'Traditional'
  const hasSDAAccount = account.type === accounts.types.AccountType.SDA

  // IRA accounts either have an account type of SDA or CDA, so if IRA account does not have SDA account type, it has CDA account type
  if (hasSepIraAccount) {
    return hasSDAAccount
      ? 'deposit-sdaAccount-IRA-SEP-accountsDropdown'
      : 'deposit-cdaAccount-IRA-SEP-accountsDropdown'
  }

  if (hasRothIraAccount) {
    return hasSDAAccount
      ? 'deposit-sdaAccount-IRA-ROTH-accountsDropdown'
      : 'deposit-cdaAccount-IRA-ROTH-accountsDropdown'
  }

  if (hasTraditionalIraAccount) {
    return hasSDAAccount
      ? 'deposit-sdaAccount-IRA-TRADITIONAL-accountsDropdown'
      : 'deposit-cdaAccount-IRA-TRADITIONAL-accountsDropdown'
  }

  return accountTypeAllyTM[account.type]
}
