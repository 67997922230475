import { Domains } from '@ally/data-types-host'
import { LoadState } from '@ally/use-data-domain/dist/cjs'
import { accounts } from '@unified-ui/ally-next-host'
import { useLobs } from '..'
import { useHostServices } from '../../HostServices'

type OutageCheck = {
  isBankOutage: boolean
  isInvestOutage: boolean
  isWealthOutage: boolean
  isMortgageOutage: boolean
  isCreditCardOutage: boolean
  isAutoOutage: boolean
  isOutage: boolean
}

export const useOutageCheck = (): OutageCheck => {
  const { hostData, featureFlags } = useHostServices()

  const { bank } = useLobs()
  const accountsData = hostData[Domains.AFG_ACCOUNTS].data
  const hasDataFailed =
    hostData[Domains.AFG_ACCOUNTS].status.loadState === LoadState.FAILED

  const isCreditCardEnabled = featureFlags.variation(
    'FF_credit-card-snapshot',
    false,
  )

  const isAutoEnabled = featureFlags.variation('FF_SPOG_WEB_UI', true)

  const isBankOutage =
    (bank && accounts.selectors.getOutages(accountsData).DEPOSIT === 'full') ||
    hasDataFailed
  const isInvestOutage =
    accounts.selectors.getOutages(accountsData).INVEST === 'full' ||
    hasDataFailed
  const isWealthOutage =
    accounts.selectors.getOutages(accountsData).WEALTH === 'full' ||
    hasDataFailed
  const isMortgageOutage =
    accounts.selectors.getOutages(accountsData).MORTGAGE === 'full' ||
    hasDataFailed
  const isCreditCardOutage =
    (isCreditCardEnabled &&
      accounts.selectors.getOutages(accountsData).CC === 'full') ||
    hasDataFailed
  const isAutoOutage =
    (isAutoEnabled &&
      accounts.selectors.getOutages(accountsData).AUTO === 'full') ||
    hasDataFailed

  const isOutage =
    isBankOutage ||
    isInvestOutage ||
    isWealthOutage ||
    isMortgageOutage ||
    isCreditCardOutage ||
    isAutoOutage

  return {
    isBankOutage,
    isInvestOutage,
    isWealthOutage,
    isMortgageOutage,
    isCreditCardOutage,
    isAutoOutage,
    isOutage,
  }
}
