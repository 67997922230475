import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Link as ReactRouterLink } from 'react-router-dom'

import {
  Box,
  get,
  Button,
  TextBody,
  Link as MuiLink,
  AccordionDEPRECATED,
  AccordionItemDEPRECATED,
} from '@ally/metronome-ui'

import { SharedHelpModal } from '../../SharedHelpModal'
import { EXPLORE_MORE_ROUTES } from './constants'
import { useLobs, useAccountStatus, useShowAccounts } from '../../hooks'
import { AccountsDetails } from '../AccountsDropdown/AccountDetails'
import { useHostServices } from '../../HostServices'
import { formatLastLogin } from '../../utils'

type NavDrawerContentProps = {
  targetRef: React.RefObject<HTMLAnchorElement>
  onLogout: () => void
}

const NavigationMenuButton = styled(Button)`
  color: white;
  background-color: ${({ theme }): string => theme.colors.toaster};
`

const AccountsAccordion = styled(AccordionItemDEPRECATED)`
  [data-accordion-component='AccordionItemButton'] {
    background-color: ${get.colors('plum')};
    padding: ${get.space('xxs')} ${get.space('md')};
    border-color: ${get.colors('gray-500')};
  }

  [data-accordion-component='AccordionItemPanel'] {
    padding: 0;
  }
`

const StyledLinkWrapper = styled.li`
  background-color: transparent;
  border-bottom: 1px solid ${get.colors('slate-3')};
  height: 45px;
`

const sharedStyles = css`
  ${({ theme: { space, fontSizes, iconSize } }): string => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    color: white;
    padding-left: calc(${space.md} + ${iconSize.xs} + 5px);
    font-size: ${fontSizes.md};
  `}
`

const StyledHelpWrapper = styled.li`
  ${sharedStyles}
  background-color: transparent;
  border-bottom: 1px solid ${get.colors('slate-3')};
  height: 45px;
  & button {
    font-size: ${get.fontSizes('md')};
  }
`

const StyledLink = styled(MuiLink)`
  a {
    ${sharedStyles}
  }
`

// This strips the extra space metronome places around content for 'CONTAINER' components:
// https://gitlab.com/allyfinancial/ally-digital/applications/ui/ally-next/metronome-ui/-/blob/main/packages/metronome-ui/src/Accordion/components/AccordionItemPanel.tsx
const AccountsAccordionContent = styled.div`
  margin: -${get.space('md')};
  margin-top: calc(-${get.space('xxs')} + -8px);
`

const StyledNavMenuList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`

const StyledText = styled(TextBody)`
  display: block;
  color: ${get.colors('white')};
`

const StatusTracker: React.FC = () => {
  const { hasActiveDepositAccounts } = useAccountStatus()

  return hasActiveDepositAccounts ? (
    <StyledLinkWrapper>
      <StyledLink variant="link" text="Status Tracker">
        <ReactRouterLink to="/status-tracker" />
      </StyledLink>
    </StyledLinkWrapper>
  ) : null
}

const NavDrawerContent: React.FC<NavDrawerContentProps> = ({
  onLogout,
  targetRef,
}) => {
  const { featureFlags, session } = useHostServices()
  const { investment: isInvestCustomer, bank: isBankCustomer } = useLobs()
  const getVariation = featureFlags.variation

  const lastLoginTimeStamp = session?.data?.lastLoginTime ?? null

  const formattedLoginTime = useMemo(
    () => (lastLoginTimeStamp ? formatLastLogin(lastLoginTimeStamp) : ''),
    [lastLoginTimeStamp],
  )

  const showAccounts = useShowAccounts()

  return (
    <>
      <Box position="relative" mt="xxl">
        <StyledNavMenuList data-testid="mobile-menu-list">
          <AccordionDEPRECATED variant="NAVIGATION">
            <StyledLinkWrapper>
              <StyledLink variant="link" text="View Snapshot">
                <ReactRouterLink to="/dashboard" ref={targetRef} />
              </StyledLink>
            </StyledLinkWrapper>
            {showAccounts ? (
              <AccountsAccordion
                title={
                  <StyledText tag="p" size="md" weight="bold">
                    Accounts
                  </StyledText>
                }
                content={{
                  variant: 'CONTAINER',
                  payload: (
                    <AccountsAccordionContent>
                      <AccountsDetails hasMaxHeight={false} inNavDrawer />
                    </AccountsAccordionContent>
                  ),
                }}
              />
            ) : (
              <></>
            )}
            <StatusTracker />
            <>
              {(isBankCustomer || isInvestCustomer) && (
                <StyledLinkWrapper>
                  <StyledLink variant="link" text="Manage Linked Accounts">
                    <ReactRouterLink to="/payments/manage-non-ally-accounts" />
                  </StyledLink>
                </StyledLinkWrapper>
              )}
            </>
            <AccordionItemDEPRECATED
              title={
                <StyledText tag="p" size="md" weight="bold">
                  Explore More From Ally
                </StyledText>
              }
              content={{
                variant: 'NAVIGATION',
                routes: EXPLORE_MORE_ROUTES(getVariation),
              }}
            />
            <StyledHelpWrapper>
              <SharedHelpModal variant="light" />
            </StyledHelpWrapper>
            <StyledLinkWrapper>
              <StyledLink variant="link" text="Open Account">
                <ReactRouterLink to="/products-and-rates" />
              </StyledLink>
            </StyledLinkWrapper>
          </AccordionDEPRECATED>
        </StyledNavMenuList>
      </Box>
      <Box p="md">
        <Box mt="md">
          <NavigationMenuButton
            variant="primary"
            text="Log Out"
            onClick={(): void => onLogout()}
            disableFullWidthSmDown
          />
          <Box mt="xs">
            <>
              {!!formattedLoginTime.length && (
                <>
                  <StyledText tag="span" size="sm">
                    Last Login
                  </StyledText>
                  <StyledText tag="span" size="sm">
                    {formattedLoginTime}
                  </StyledText>
                </>
              )}
            </>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export { NavDrawerContent }
