import { AutoLoginService } from '@ally/federated-types'
import React, { createContext, FC, useContext, useRef } from 'react'
import { useSession$ } from '../providers/session/useSession$'
import getAutoLoginService from './autoLogin'

export type Services = {
  autoLogin: AutoLoginService
}

const ServicesContext = createContext<Services>({} as any)

export const useServices = (): Services => useContext(ServicesContext)

export const ServicesProvider: FC = ({ children }) => {
  const session$ = useSession$()

  const { current: autoLogin } = useRef(getAutoLoginService({ session$ }))

  return (
    <ServicesContext.Provider value={{ autoLogin }}>
      {children}
    </ServicesContext.Provider>
  )
}
