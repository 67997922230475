import { useMemo } from 'react'
import { accounts, customer as uuipCustomer } from '@unified-ui/ally-next-host'
import { Domains } from '@ally/data-types-host'
import { useHostServices } from '../../HostServices'

type EtinCertificationValues = {
  isEtinUncertified: boolean
}

export const useEtinCertification = (): EtinCertificationValues => {
  const { hostData } = useHostServices()
  const customerData = hostData[Domains.AFG_CUSTOMER].data
  const accountData = hostData[Domains.AFG_ACCOUNTS].data

  const etinStatusCode = useMemo(
    () => uuipCustomer.selectors.getEtinStatusCode(customerData),
    [hostData],
  )
  const hasInternalNonIraActiveAccounts = useMemo(() => {
    const depositAccounts: accounts.types.Account[] =
      accounts.selectors.deposit.getOpenInternal(accountData) || []
    return !!depositAccounts.filter(acct => !acct.indicator?.retirement).length
  }, [hostData])

  const isEtinUncertified = useMemo(
    () =>
      etinStatusCode?.toLowerCase() === 'non-certified' &&
      hasInternalNonIraActiveAccounts,
    [etinStatusCode, hasInternalNonIraActiveAccounts],
  )

  return { isEtinUncertified }
}
