import React, { useContext } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { deprecated } from '@ally/metronome-icons'

import {
  Box,
  Icon,
  DropdownMenuDEPRECATED,
  TextBody,
  get,
} from '@ally/metronome-ui'

import { useHistory } from 'react-router-dom'
import { DropdownTrigger } from './DropdownTrigger'
import { ActionBarContext } from './ActionBarContext'
import { useLobs, useUnreadMessageCount } from '../hooks'
import { useHostServices } from '../HostServices'
import { dropdownTriggerAriaLabel, getInvestTitle } from '../utils'

interface StyledMessagesWrapperProps {
  shouldShowNotifications: boolean
}

type StyledMessagesWrapperType = ({
  shouldShowNotifications,
}: StyledMessagesWrapperProps) => string

const shouldShowNotificationsStyle: StyledMessagesWrapperType = props => {
  if (props.shouldShowNotifications) {
    return `
      display: inline-block;
      ::before {
        color: #d42825;
        font-size: 16px;
        content: '';
        background-color: #d42825;
        position: absolute;
        display: inline-block;
        top: 0;
        left: 19px;
        width: 8px;
        border-radius: 50%;
        height: 8px;
        z-index: 1;
      }
    `
  }

  return `
    display: inline-block;
  `
}

const StyledMessagesWrapper = styled(Box)`
  ${shouldShowNotificationsStyle}
`

const StyledNotification = styled(TextBody)(
  ({ theme: { colors, fontSizes, space } }): string => `
    display: block;
    margin: ${space.xxs} 0 ${space.xxs} ${space.xxs};
    ::before {
      display: inline-block;
      font-size: ${fontSizes.xs};
      content: '';
      background-color: ${colors.error};
      width: 8px;
      border-radius: 50%;
      height: 8px;
      margin-right: ${space.xxs};
    }
  `,
)

const LinkTextBody = styled(TextBody)`
  color: ${get.colors('bluesuedeshoes')};
`

const StyledInvestContent = styled(Box)`
  width: 440px;
  white-space: normal;
`

interface MessageLinkProps {
  isBankUser: boolean
  isDebtPlanCustomer: boolean
}

const MessagesIcon: React.FC<{ shouldShowNotifications: boolean }> = ({
  shouldShowNotifications,
}) => {
  return (
    <StyledMessagesWrapper shouldShowNotifications={shouldShowNotifications}>
      <Icon
        icon={deprecated.muiMailClosed}
        fill="bluesuedeshoes"
        size="md"
        ariaHidden
      />
    </StyledMessagesWrapper>
  )
}

export const notificationTextLegacy = 'You have a new message'
export const NotificationText: React.FC<{ count: number }> = ({ count }) => {
  if (count > 1) {
    return (
      <StyledNotification tag="span" size="sm">
        You have {count} unread messages
      </StyledNotification>
    )
  }

  if (count === 1) {
    return (
      <StyledNotification tag="span" size="sm">
        You have an unread message
      </StyledNotification>
    )
  }

  return (
    <TextBody tag="span" size="sm">
      You have no unread messages
    </TextBody>
  )
}

export const BankMessagesLink: React.FC<MessageLinkProps> = ({
  isBankUser,
  isDebtPlanCustomer,
}) => {
  const {
    bankUnreadMessageCount,
    hasBankUnreadMessages,
  } = useUnreadMessageCount()
  const { featureFlags } = useHostServices()
  const isAutoEnabled = featureFlags.variation('FF_SPOG_WEB_UI', true)

  const getBankTitle = (): string => {
    if (isDebtPlanCustomer && !isBankUser) return 'Plans'
    if (isDebtPlanCustomer && isBankUser) return 'Bank Accounts and Plans'
    return 'Bank Accounts'
  }

  return (
    <Box display="flex" flexDirection="column">
      <LinkTextBody tag="span" size="sm" weight="bold">
        {getBankTitle()}
      </LinkTextBody>
      {hasBankUnreadMessages && !isAutoEnabled && (
        <StyledNotification tag="span" size="sm">
          {notificationTextLegacy}
        </StyledNotification>
      )}
      {isAutoEnabled && <NotificationText count={bankUnreadMessageCount} />}
    </Box>
  )
}

const InvestMessagesLink: React.FC<{
  investTitle: string
}> = ({ investTitle }) => {
  const { featureFlags } = useHostServices()
  const isAutoEnabled = featureFlags.variation('FF_SPOG_WEB_UI', true)
  const isInvestSecureMessagesEnabled = featureFlags.variation(
    'FF_Invest-Secure-Message',
    false,
  )

  const { investUnreadMessageCount } = useUnreadMessageCount()

  if (isInvestSecureMessagesEnabled) {
    return (
      <Box display="flex" flexDirection="column">
        <LinkTextBody tag="span" size="sm" weight="bold">
          {investTitle}
        </LinkTextBody>

        <NotificationText count={investUnreadMessageCount} />
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column">
      <LinkTextBody tag="span" size="sm" weight="bold">
        {investTitle}
      </LinkTextBody>
      {!isAutoEnabled ? (
        <>
          <Box my="xxs">
            <TextBody tag="span" size="md" weight="bold">
              support@invest.ally.com
            </TextBody>
          </Box>
          <Box mb="xxs">
            <TextBody tag="span" size="sm">
              We can&apos;t respond to certain account-sensitive questions
              through email{' '}
            </TextBody>
          </Box>
        </>
      ) : (
        <>
          <StyledInvestContent mb="xxs">
            <TextBody tag="span" size="sm">
              You shouldn&apos;t include any account information in your email
              to us. For help with your account, trades, or holdings, call
              1-855-880-2559 inside the U.S.
            </TextBody>
          </StyledInvestContent>
          <Box my="xxs">
            <LinkTextBody tag="span" size="sm" weight="bold">
              support@invest.ally.com
            </LinkTextBody>
          </Box>
        </>
      )}
    </Box>
  )
}

export const CreditCardLink: React.FC = () => {
  return (
    <LinkTextBody tag="span" size="sm" weight="bold">
      Credit Cards
    </LinkTextBody>
  )
}

export const HomeLoansLink: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column">
      <LinkTextBody tag="span" size="sm" weight="bold">
        Home Loans
      </LinkTextBody>
      <Box mb="xxs">
        <TextBody tag="span" size="sm">
          Select Account Management from your Home Loans menu, then choose
          Messages.
        </TextBody>
      </Box>
    </Box>
  )
}

export const VehiclesLink: React.FC = () => {
  const { vehicleUnreadMessageCount } = useUnreadMessageCount()
  return (
    <Box display="flex" flexDirection="column">
      <LinkTextBody tag="span" size="sm" weight="bold">
        Vehicles
      </LinkTextBody>
      <NotificationText count={vehicleUnreadMessageCount} />
    </Box>
  )
}

const InvestMenuItem: React.FC = () => {
  const history = useHistory()
  const { investment } = useLobs()
  const { isInvestCustomer, isWealthCustomer } = useContext(ActionBarContext)
  const { featureFlags } = useHostServices()
  const isPersonalAdviceEnabled = featureFlags.variation(
    'FF_personal-advisor',
    false,
  )
  const isInvestSecureMessagesEnabled = featureFlags.variation(
    'FF_Invest-Secure-Message',
    false,
  )

  const showInvestMessaging = Boolean(investment || isWealthCustomer)

  const investTitle = getInvestTitle(
    isInvestCustomer,
    isWealthCustomer,
    isPersonalAdviceEnabled,
  )

  if (!showInvestMessaging) {
    return <></>
  }

  if (isInvestSecureMessagesEnabled && showInvestMessaging) {
    return (
      <DropdownMenuDEPRECATED.MenuItemLink
        href="/investments/secure-messages"
        withAllyTM="messages-investments"
        content={<InvestMessagesLink investTitle={investTitle} />}
        useRouterLink
        onSelection={(): void => history.push('/investments/secure-messages')}
      />
    )
  }

  // Legacy Investment Messaging
  return (
    showInvestMessaging && (
      <DropdownMenuDEPRECATED.MenuItemLink
        href="mailto:support@invest.ally.com"
        withAllyTM={`${investTitle}'-support@invest.ally.com'`}
        content={<InvestMessagesLink investTitle={investTitle} />}
      />
    )
  )
}

const BankMenuItem: React.FC = () => {
  const history = useHistory()
  const { bank: isBankUser } = useLobs()
  const { session, featureFlags } = useHostServices()
  const isDebtPlanEnabled = featureFlags.variation(
    'FF_debt-plan-snapshot',
    false,
  )
  const isDebtPlanCustomer =
    (isDebtPlanEnabled &&
      session?.data?.relationships?.tools?.debtReduction?.status ===
        'ACTIVE') ||
    false

  return isBankUser || isDebtPlanCustomer ? (
    <DropdownMenuDEPRECATED.MenuItemLink
      href="/bank/secure-messages"
      withAllyTM="Bank Accounts-Secure Messages"
      content={
        <BankMessagesLink
          isBankUser={isBankUser}
          isDebtPlanCustomer={isDebtPlanCustomer}
        />
      }
      useRouterLink
      onSelection={(): void => history.push('/bank/secure-messages')}
    />
  ) : null
}

const CreditCardMenuItem: React.FC = () => {
  const history = useHistory()
  const { creditcard: isCreditCardUser } = useLobs()
  const { featureFlags } = useHostServices()
  const isCreditCardEnabled = featureFlags.variation(
    'FF_credit-card-snapshot',
    false,
  )
  const isCreditCardCustomer = isCreditCardEnabled && isCreditCardUser
  return isCreditCardCustomer ? (
    <DropdownMenuDEPRECATED.MenuItemLink
      href="/sso/credit-card/Service/AccountNotification"
      withAllyTM="messages-credit-card"
      content={<CreditCardLink />}
      useRouterLink
      onSelection={(): void =>
        history.push('/sso/credit-card/Service/AccountNotification')
      }
    />
  ) : null
}

interface HomeLoansMenuItemProps {
  showHomeLoans: boolean | undefined
}
const HomeLoansMenuItem: React.FC<HomeLoansMenuItemProps> = ({
  showHomeLoans,
}) => {
  const history = useHistory()
  return showHomeLoans ? (
    <DropdownMenuDEPRECATED.MenuItemLink
      href="/sso/home-loans"
      withAllyTM="messages-homeLoans"
      content={<HomeLoansLink />}
      useRouterLink
      onSelection={(): void => history.push('/sso/home-loans')}
    />
  ) : null
}

const VehiclesMenuItem: React.FC = () => {
  const history = useHistory()
  const { auto: isAutoUser } = useLobs()
  const { featureFlags } = useHostServices()
  const isAutoEnabled = featureFlags.variation('FF_SPOG_WEB_UI', true)
  const isAutoCustomer = isAutoEnabled && isAutoUser
  return isAutoCustomer ? (
    <DropdownMenuDEPRECATED.MenuItemLink
      href="/sso/auto/secure-messages"
      withAllyTM="vehiclesSecureMessages"
      content={<VehiclesLink />}
      useRouterLink
      onSelection={(): void => history.push('/sso/auto/secure-messages')}
    />
  ) : null
}

interface MessagesDropdownProps {
  showHomeLoans?: boolean
  closeNotifications: () => void
}

const MessagesDropdown: React.FC<MessagesDropdownProps> = ({
  showHomeLoans,
  closeNotifications,
}) => {
  // TODO: change this to use the `useMediaSize` query from `metronome-ui` once
  // v19 is released. This is a temporary hard-coded fix.
  const isXlUp = useMediaQuery({ minWidth: 1024 })

  const {
    hasBankUnreadMessages,
    bankUnreadMessageCount,
    hasVehicleUnreadMessages,
    vehicleUnreadMessageCount,
    hasInvestUnreadMessages,
    investUnreadMessageCount,
  } = useUnreadMessageCount()

  return (
    <Box data-testid="messages" my="xxs">
      <DropdownMenuDEPRECATED
        topPos={36}
        caretOffsetX={isXlUp ? 27 : -18}
        menuOffsetX={24}
        onMenuToggle={(isOpen: boolean): void => {
          if (isOpen) closeNotifications()
        }}
      >
        <DropdownMenuDEPRECATED.Trigger
          ariaLabel={(isOpen: boolean): string =>
            dropdownTriggerAriaLabel({
              menuName: 'Messages',
              unreadCount:
                bankUnreadMessageCount +
                vehicleUnreadMessageCount +
                investUnreadMessageCount,
              isOpen,
            })
          }
          content={
            <DropdownTrigger copy="Messages">
              <MessagesIcon
                shouldShowNotifications={
                  hasBankUnreadMessages ||
                  hasVehicleUnreadMessages ||
                  hasInvestUnreadMessages
                }
              />
            </DropdownTrigger>
          }
        />
        <DropdownMenuDEPRECATED.MenuContainer>
          <>
            <InvestMenuItem />
            <BankMenuItem />
            <CreditCardMenuItem />
            <VehiclesMenuItem />
            <HomeLoansMenuItem showHomeLoans={showHomeLoans} />
          </>
        </DropdownMenuDEPRECATED.MenuContainer>
      </DropdownMenuDEPRECATED>
    </Box>
  )
}

export default MessagesDropdown
